import React, { useState } from "react";
import axios from "axios";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export default function ContactForm() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [message2, setMessage2] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState(false);

  const send = async (e) => {
    e.preventDefault();

    // Check if the captcha was skipped or not
    if (!executeRecaptcha) {
      return;
    }

    const captchaResult = await executeRecaptcha("homepage");

    if (!message2) {
      axios
        .post("/.netlify/functions/send-email", {
          name,
          email,
          phone,
          message,
          captchaResult,
        })
        .then(() => {
          setFormSubmitted(true);
        })
        .catch((error) => {
          setFormSubmitted(true);
          setFormError(true);
        });
    }
  };

  const form = () => {
    return (
      <form
        className="grid grid-cols-1 py-4 md:grid-cols-2 gap-1"
        onSubmit={(e) => send(e)}
      >
        <div className="col-span-2">
          <label className="hidden" htmlFor="name">
            Name:
          </label>
          <input
            name="name"
            type="text"
            placeholder="Name"
            required
            onChange={(e) => setName(e.target.value)}
            className="p-2 w-full font-semibold placeholder-gray-500 border rounded-lg outline-none focus:ring-accent-blue focus:ring-1 flex-grow"
          />
        </div>
        <div className="md:col-span-1 col-span-2">
          <label className="hidden" htmlFor="email">
            Email:
          </label>
          <input
            name="email"
            type="email"
            placeholder="Email"
            required
            onChange={(e) => setEmail(e.target.value)}
            className="p-2 w-full font-semibold placeholder-gray-500 border rounded-lg outline-none focus:ring-accent-blue focus:ring-1 flex-grow"
          />
        </div>
        <div className="md:col-span-1 col-span-2">
          <label className="hidden" htmlFor="email">
            Phone:
          </label>
          <input
            name="phone"
            type="text"
            placeholder="Phone"
            required
            onChange={(e) => setPhone(e.target.value)}
            className="p-2 w-full font-semibold placeholder-gray-500 border rounded-lg outline-none focus:ring-accent-blue focus:ring-1 flex-grow"
          />
        </div>
        <div className="col-span-2">
          <label className="hidden" htmlFor="message">
            Message:
          </label>
          <textarea
            name="message"
            placeholder="Message"
            type="textarea"
            required
            onChange={(e) => setMessage(e.target.value)}
            className="w-full p-2 font-semibold placeholder-gray-500 border rounded-lg outline-none focus:ring-accent-blue focus:ring-1"
          />
          <input onChange={(e) => setMessage2(true)} className="hidden" />
        </div>
        <div className="col-span-2">
          <button
            type="submit"
            className="w-full py-4 text-sm tracking-wide bg-primary text-white uppercase rounded-lg shadow-xl outline-none lg:text-base bg-primary-green hover:bg-opacity-75 focus:outline-none"
          >
            Send
          </button>
        </div>
      </form>
    );
  };

  if (formSubmitted && !formError) {
    return (
      <>
        <div>
          {form()}
          <p className="text-center text-lg">Thank you for contacting us someone will get back to you as soon as possible.</p>
        </div>
      </>
    );
  } else if (formSubmitted && formError) {
    return (
      <>
        <div>
          {form()}
          <p className="text-center text-lg">
            There was an error sending your details please try again. If the problem persists try and give us a call.
          </p>
        </div>
      </>
    );
  } else {
    return <>{form()}</>;
  }
}
