import React from "react";
import GoogleMap from "../../components/googlemap";
import ContactForm from "../../components/contactform";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import Container from "../../components/Container";
import SeoContent from "../../components/seoContent";
import { useStaticQuery, graphql } from "gatsby";

const Contact = () => {
  const query = graphql`
  query {
    strapiContactUs {
      Seo {
        metaDescription
        metaTitle
        shareImage {
          localFile {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
      }
      Address
      Content
      Email
      Heading
      PhoneNumber
      Subheading
    }
  }
`;
const data = useStaticQuery(query);

const isSingleSiteEnvSetting = process.env.IS_SINGLE_PAGE_SITE;

const isSingleSite = isSingleSiteEnvSetting === "true";

  return (
    <>
      <SeoContent pageSeo={data.strapiContactUs.Seo}/>
      <div className="leading-relaxed bg-primary tracking-wide flex flex-col overflow-y-hidden font-custom">
        <NavBar isSingleSite={isSingleSite}/>
        <GoogleMap className="w-screen" />
        <Container transparent={true} 
          styles={{ height: "40vh" }}
        ></Container>
        <Container className="z-50">
          <div className="m-auto w-1/3 pb-6">
            <hr className="bg-gray-700" />
            <h1 className="m-auto text-center text-xl py-4 uppercase font-semibold">
              {data.strapiContactUs.Heading}
            </h1>
            <hr className="bg-gray-700" />
          </div>
          <div className="grid grid-cols-1 py-4 md:grid-cols-2 gap-4">
            <div className="text-center md:text-left">
              <h1 className="text-xl font-semibold py-1">{data.strapiContactUs.Subheading}</h1>
              <p className="text-lg py-1 md:w-4/5">
                {data.strapiContactUs.Content}
              </p>
              <address className="text-lg md:w-4/5 lg:w-1/2 not-italic py-1">
                {data.strapiContactUs.Address}
              </address>
              <p className="text-lg py-1">Phone: {data.strapiContactUs.PhoneNumber}</p>
              <a href="mailto:beltanebandb@btinternet.com" className="text-lg py-1">
                Email: {data.strapiContactUs.Email}
              </a>
            </div>

            <ContactForm />
          </div>
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default Contact;
