import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

const mapStyles = {
  width: "100%",
  height: "39%",
};

export class GoogleMap extends Component {
  render() {
    return (
      <Map
        google={this.props.google}
        zoom={14}
        style={mapStyles}
        initialCenter={{
          lat: 51.024192869395485,
          lng: -2.741445430704541,
        }}
      >
        <Marker name={"Current location"} />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyC7ma2cTFAkv_ZDr6Z5qe4FojnBr3lrboU",
})(GoogleMap);
